
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useEffect, useState } from "react";

const steps = ["Login", "Verify OTP"];

export default function LoginStepper({ verifyOTPMailSent }) {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (verifyOTPMailSent === true) {
      setActiveStep(1);
    } else {
      setActiveStep(0);
    }
  }, [verifyOTPMailSent]);
  return (
    <Box sx={{ width: "100%", my: 4 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
