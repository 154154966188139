import { Alert, Button } from "@mui/material";
import ExtensionIcon from "@mui/icons-material/Extension";
const SpecialMessage = () => {
  return (
    <div>
      <Alert
        variant="filled"
        severity="info"
        action={
          <Button
            startIcon={<ExtensionIcon />}
            color="inherit"
            onClick={() =>
              window.open(
                "https://chromewebstore.google.com/detail/trackmyprice-amazon-price/cidgjnicdgepohdjmdnjiebmkbenojkh",
                "_blank"
              )
            }
            size="small"
          >
            Chrome Extension
          </Button>
        }
      >
        Save time, money! Get real-time analysis. Install now! 🚀
      </Alert>
    </div>
  );
};

export default SpecialMessage;
